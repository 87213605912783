import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalBackpackService } from 'src/app/medicalbackpack.service';
import { MedicalBackpack } from 'src/app/shared/medical-backpack';

@Component({
  selector: 'app-medical-backpack',
  templateUrl: './medical-backpack.component.html',
  styleUrls: ['./medical-backpack.component.scss']
})
export class PrepairMedicalBackpackComponent implements OnInit {

  readonly nullSelectionValue = "---";
  medicalBackpack: MedicalBackpack[] = [];
  mbArea: string[] = [];
  selectedArea: string = this.nullSelectionValue;
  filteredMedicalBackpack: MedicalBackpack[] = [];
  selectedMedicalBackpack = null;

  pageMedicalBackpack = 1;
  pageSizeMedicalBackpack = 10;
  collectionSizeMedicalBackpack = 0;

  constructor(public restApi: MedicalBackpackService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadMedicalBackpack(params.col);
    });
  }

  loadMedicalBackpack(collection = undefined) {
    this.restApi.getMedicalBackpack(collection).subscribe((mbs) => {
      this.medicalBackpack = mbs;
      this.filteredMedicalBackpack = mbs;
      this.collectionSizeMedicalBackpack = this.filteredMedicalBackpack.length;
      const mapA = new Map();
      this.mbArea = [this.nullSelectionValue];
      for (const mb of mbs) {
        if (!mapA.has(mb.area)) {
          mapA.set(mb.area, true);
          this.mbArea.push(mb.area);
        }
      }
    });
  }

  rowSelected(mb: MedicalBackpack){
    this.selectedMedicalBackpack = mb;
  }

  getMedicalBackpackPage(): MedicalBackpack[] {
    return this.filteredMedicalBackpack
      .slice((this.pageMedicalBackpack - 1) * this.pageSizeMedicalBackpack, (this.pageMedicalBackpack - 1) * this.pageSizeMedicalBackpack + this.pageSizeMedicalBackpack);
  }

  changeSelection() {
    if (this.selectedArea == this.nullSelectionValue) {
      this.filteredMedicalBackpack = this.medicalBackpack;
    }
    else {
      this.filteredMedicalBackpack = this.medicalBackpack.filter(mb => {
        if (mb.area == this.selectedArea) {
          return mb;
        }
      });
    }
    this.selectedMedicalBackpack = null;
    this.pageMedicalBackpack = 1;
    this.pageSizeMedicalBackpack = this.collectionSizeMedicalBackpack;
  }

  print() {
    var pageSizeBefore = this.pageSizeMedicalBackpack;
    this.pageSizeMedicalBackpack = this.collectionSizeMedicalBackpack;
    setTimeout(() => {
      window.print();
      this.pageSizeMedicalBackpack = pageSizeBefore;
    });
  }

}
