import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhysioBackpackService } from 'src/app/physiobackpack.service';
import { PhysioBackpack } from 'src/app/shared/physio-backpack';

@Component({
  selector: 'app-physio-backpack',
  templateUrl: './physio-backpack.component.html',
  styleUrls: ['./physio-backpack.component.scss']
})
export class PrepairPhysioBackpackComponent implements OnInit {

  readonly nullSelectionValue = "---";
  physioBackpack: PhysioBackpack[] = [];
  mbArea: string[] = [];
  selectedArea: string = this.nullSelectionValue;
  filteredPhysioBackpack: PhysioBackpack[] = [];
  selectedPhysioBackpack = null;

  pagePhysioBackpack = 1;
  pageSizePhysioBackpack = 10;
  collectionSizePhysioBackpack = 0;

  constructor(public restApi: PhysioBackpackService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadPhysioBackpack(params.col);
    });
  }

  loadPhysioBackpack(collection = undefined) {
    this.restApi.getPhysioBackpack(collection).subscribe((mbs) => {
      this.physioBackpack = mbs;
      this.filteredPhysioBackpack = mbs;
      this.collectionSizePhysioBackpack = this.filteredPhysioBackpack.length;
      const mapA = new Map();
      this.mbArea = [this.nullSelectionValue];
      for (const mb of mbs) {
        if (!mapA.has(mb.area)) {
          mapA.set(mb.area, true);
          this.mbArea.push(mb.area);
        }
      }
    });
  }

  getPhysioBackpackPage(): PhysioBackpack[] {
    return this.filteredPhysioBackpack
      .slice((this.pagePhysioBackpack - 1) * this.pageSizePhysioBackpack, (this.pagePhysioBackpack - 1) * this.pageSizePhysioBackpack + this.pageSizePhysioBackpack);
  }

  changeSelection() {
    if (this.selectedArea == this.nullSelectionValue) {
      this.filteredPhysioBackpack = this.physioBackpack;
    }
    else {
      this.filteredPhysioBackpack = this.physioBackpack.filter(mb => {
        if (mb.area == this.selectedArea) {
          return mb;
        }
      });
    }
    this.selectedPhysioBackpack = null;
    this.pagePhysioBackpack = 1;
    this.pageSizePhysioBackpack = this.collectionSizePhysioBackpack;
  }

  print() {
    var pageSizeBefore = this.pageSizePhysioBackpack;
    this.pageSizePhysioBackpack = this.collectionSizePhysioBackpack;
    setTimeout(() => {
      window.print();
      this.pageSizePhysioBackpack = pageSizeBefore;
    });
  }

}
