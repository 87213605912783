export class Share {
    _id: string = null;
    name: string = null;
    url: string = null;
    url_ios: string = null;
    url_android: string = null;
    type: string = null;
    highlight: boolean = false;

    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
    }
}
