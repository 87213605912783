import { environment } from '../../environments/environment';

export class CommunicationFlow {
    _id: string = null;
    title: string = null;
    schema: string = null;
    internal_schema: string = null;
    external_schema : string = null;
    
    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
    }
}