export class Emergency {
    id: string;
    title: string;
    type: string;
    steps: StringValue[];
    schema: string;
    images: Image[];
    links: StringValue[];
    schema_details: SchemaDetails[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
        this.steps = []
        if (values['steps']) {
            values['steps'].forEach(s => {
                this.steps.push(new StringValue(s))
            });
        }
        this.images = []
        if (values['images']) {
            values['images'].forEach(s => {
                this.images.push(new Image(s))
            });
        }
        this.links = []
        if (values['links']) {
            values['links'].forEach(s => {
                this.links.push(new StringValue(s))
            });
        }
        this.schema_details = []
        if (values['schema_details']) {
            values['schema_details'].forEach(s => {
                this.schema_details.push(new SchemaDetails(s))
            });
        }
    }
}

class StringValue {
    value: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

class Image {
    path: string;

    constructor(values: Object = {}) {
        this.path = values['value']['path'];
    }
}

class SchemaDetails {
    title: string;
    steps: StringValue[];

    constructor(values: Object = {}) {
        if (values['value']['title']) {
            this.title = values['value']['title']
        }
        this.steps = []
        if (values['value']['steps']) {
            values['value']['steps'].forEach(s => {
                this.steps.push(new StringValue(s))
            });
        }
    }
}