
export class Painkiller {
    _id: string = null;
    medicament: string = null;
    area_application: string = null;
    advantage: string = null;
    disadvantage: string = null;
    dosage: string = null;
    periode: string = null;
    cave: string = null;
    
    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
    }
}
