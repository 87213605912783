
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { PhysioBackpack } from './shared/physio-backpack';

@Injectable({
  providedIn: 'root'
})

export class PhysioBackpackService extends DefaultService {

  getPhysioBackpack(col = 'Physiorucksack'): Observable<PhysioBackpack[]> {
    return this.http.get(this.apiURL + '/' + col, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => {
          const entries = response['entries'];
          return entries.map((entry) => {
            return new PhysioBackpack(entry);
          });
        }),
        map(entries => entries.sort(function(a,b){
          var compareArea = a.area.localeCompare(b.area);
          return  compareArea === 0 ? a.content.localeCompare(b.content) : compareArea;
        }))
      )
  }
}