import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShareService } from 'src/app/share.service';
import { Share } from 'src/app/shared/share';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class PrepairShareComponent implements OnInit {

  shares: Share[] = [];

  constructor(public restApi: ShareService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadShares(params.col);
    });
  }

  loadShares(collection = undefined){
    this.restApi.getShares(collection).subscribe(s => {
      this.shares = s
    });
  }
}
