import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultSingletonsService } from './default.singletons.service';
import { EmergencyCall } from './shared/emergency-call';

@Injectable({
  providedIn: 'root'
})

export class EmergencyCallService extends DefaultSingletonsService {

  getEmergencyCall(col = 'EmergencyCall'): Observable<EmergencyCall> {
    return this.http.get(this.apiURL + '/' + col, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => { return new EmergencyCall(response)})
      )
  }
}