export class Contact {
    _id: string = null;
    firstname: string = null;
    lastname: string = null;
    mail: string = null;
    discipline: string[] = [];
    team: string[] = [];
    type: string = null;
    trainingGroup: string = null;
    mobile: string = null;
    phone_office: string = null;
    mail_office: string = null;
    typeAddress: string = null;
    companyName: string = null;
    street: string = null;
    zip: string = null;
    city: string = null;
    country: string = null;
    comment: string = null;
    supplyCenterCity: string = null;

    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
    }
}
