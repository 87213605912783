
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { Medicine } from './shared/medicine';

@Injectable({
  providedIn: 'root'
})

export class MedicineService extends DefaultService {

  getMedicine(col = 'Medikament'): Observable<Medicine[]> {
    return this.http.get(this.apiURL + '/' + col, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => {
          const entries = response['entries'];
          return entries.map((entry) => {
            return new Medicine(entry);
          });
        }),
        map(entries => entries.sort(function(a,b){
          return  a.medicament.localeCompare(b.medicament);
        }))
      )
  }
}