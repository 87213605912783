import { Component, OnInit } from '@angular/core';
import { CommunicationFlow } from '../shared/communication-flow';
import { CommunicationFlowService } from '../communication-flow.service';
import { CommunicationFlowContactService } from '../communication-flow-contact.service';
import { CommunicationFlowContactList } from '../shared/communication-flow-contact';
import { ConfigService } from '../config.service';
import { ConfigURL } from '../shared/config';
import { isNull, isNullOrUndefined } from 'util';
import { isDefined } from '@angular/compiler/src/util';

@Component({
  selector: 'app-communication-flow',
  templateUrl: './communication-flow.component.html',
  styleUrls: ['./communication-flow.component.scss']
})
export class CommunicationFlowComponent implements OnInit {

  selectedFlow: CommunicationFlow = null;
  communicationFlow: CommunicationFlow[] = [];
  contact: CommunicationFlowContactList= null;

  constructor(private config: ConfigService, public restApi: CommunicationFlowService, public restApiC: CommunicationFlowContactService) { }

  ngOnInit() {
    this.config.getUrlElements('/communicationflow').then((urls: ConfigURL[]) => {
      console.log(urls)
      if(urls.length == 0){
        this.loadCommunicationFlow();
        this.loadCommunicationFlowContacts();
      }
      else{
        this.loadCommunicationFlow(urls[0].collection == null ? undefined : urls[0].collection);
        this.loadCommunicationFlowContacts(urls[0].singleton == null ? undefined : urls[0].singleton);
      }
    });
  }

  loadCommunicationFlow(collection = undefined){
    this.restApi.getCommunicationFlow(collection).subscribe(cf => {
      this.communicationFlow = cf;
    });
  }

  loadCommunicationFlowContacts(singleton = undefined){
    this.restApiC.getContacts(singleton).subscribe(cfc => {
      this.contact = cfc;
    });
  }

  changeSelection(flow: CommunicationFlow) {
    //console.log(flow)
    this.selectedFlow = flow;
  }
}
