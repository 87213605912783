import { Component, OnInit } from '@angular/core';
import { MedicineService } from 'src/app/medicine.service';
import { Medicine } from 'src/app/shared/medicine';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-medicine',
  templateUrl: './medicine.component.html',
  styleUrls: ['./medicine.component.scss']
})
export class InternalMedicineComponent implements OnInit {

  medicine: Medicine[] = [];
  filteredMedicine: Medicine[] = [];
  selectedMedicine = null;

  pageMedicine = 1;
  pageSizeMedicine = 10;
  collectionSizeMedicine = 0;
  
  filter = new FormControl('');

  constructor(public restApi: MedicineService, private activatedRoute: ActivatedRoute) {
    //this.filter.registerOnChange(this.filterMedicine);
    this.filter.valueChanges.subscribe(() => this.filterMedicine())
   }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadMedicine(params.col);
    });
  }

  loadMedicine(collection = undefined) {
    this.restApi.getMedicine(collection).subscribe((ml) => {
      this.medicine = ml;
      this.filteredMedicine = ml;
      this.collectionSizeMedicine = this.filteredMedicine.length;
    });
  }

  filterMedicine() {
    if(this.filter.value == ''){
      this.filteredMedicine = this.medicine;
    }
    else{
      this.filteredMedicine = this.medicine.filter(m => {
        const term = this.filter.value.toLowerCase();
        return m.medicament.toLowerCase().includes(term)
          || m.indication.toLowerCase().includes(term);
      });
    }
    this.selectedMedicine = null;
    this.collectionSizeMedicine = this.filteredMedicine.length;
    this.pageMedicine = 1;
  }

  rowSelected(pk: Medicine){
    this.selectedMedicine = pk;
  }

  getMedicinePage(): Medicine[] {
    return this.filteredMedicine
      .slice((this.pageMedicine - 1) * this.pageSizeMedicine, (this.pageMedicine - 1) * this.pageSizeMedicine + this.pageSizeMedicine);
  }

  print() {
    var pageSizeBefore = this.pageSizeMedicine;
    this.pageSizeMedicine = this.collectionSizeMedicine;
    setTimeout(() => {
      window.print();
      this.pageSizeMedicine = pageSizeBefore;
    });
  }

}
