import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private swUpdate: SwUpdate) { }

  ngOnInit() {

    this.swUpdate.available.subscribe(event => {

      console.log('[DSVAid] Update is available');
      if (confirm("New version available. Load New Version?")) {
        window.location.reload();
      }

    });

  }
}
