import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/contact.service';
import { Contact } from 'src/app/shared/contact';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap/tabset/tabset';
import { ActivatedRoute } from '@angular/router';
import { isDefined } from '@angular/compiler/src/util';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class PrepairContactComponent implements OnInit {

  readonly nullSelectionValue = "---";
  contacts: Contact[] = [];
  filteredContacts: Contact[] = [];
  filteredSCContacts: Contact[] = [];
  contactDiscipline: string[] = [];
  contactType: string[] = [];
  supplyCenterCitys: string[] = [];
  selectedDiscipline: string = this.nullSelectionValue;
  selectedType: string = this.nullSelectionValue;
  selectedCity: string = this.nullSelectionValue;
  selectedContact: Contact = null;

  pageContacts = 1;
  pageSizeContacts = 5;
  collectionSizeContacts = 0;
  pageSCContacts = 1;
  pageSizeSCContacts = 5;
  collectionSizeSCContacts = 0;

  constructor(private restApi: ContactService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadContacts(params.col);
    });
  }

  loadContacts(collection = undefined) {
    return this.restApi.getContacts(collection).subscribe((cs) => {
      this.contacts = cs;
      const mapD = new Map();
      this.contactDiscipline = [this.nullSelectionValue];
      const mapT = new Map();
      this.contactType = [this.nullSelectionValue];
      const mapC = new Map();
      this.supplyCenterCitys = [this.nullSelectionValue];
      for (const c of cs) {
        c.discipline.forEach(cd => {
          if (!mapD.has(cd)) {
            mapD.set(cd, true);
            this.contactDiscipline.push(cd);
          }
        });
        if (!mapT.has(c.type)) {
          mapT.set(c.type, true);
          this.contactType.push(c.type);
        }
        if (c.supplyCenterCity != null && !mapC.has(c.supplyCenterCity)) {
          mapC.set(c.supplyCenterCity, true);
          this.supplyCenterCitys.push(c.supplyCenterCity);
        }
      }
      this.contactDiscipline.sort();
      this.contactType.sort();
      this.supplyCenterCitys.sort();
    });
  }

  changeSelection(field: string) {
    // console.log("type: "+this.selectedType+" discipline: "+this.selectedDiscipline);
    this.selectedContact = null;
    if(field == "selectType"){
      this.selectedDiscipline == null;
    }

    this.filteredContacts = this.contacts.filter(c => {
      if (c.type == this.selectedType) {
        if (this.selectedDiscipline == this.nullSelectionValue) {
          return c;
        }
        else if (c.discipline.find(d => d == this.selectedDiscipline) != null) {
          return c;
        }
      }
    });
    this.filteredContacts.sort(function(a,b){
      var compareValue = a.lastname.localeCompare(b.lastname);
      return  compareValue === 0 ? a.firstname.localeCompare(b.firstname) : compareValue;
    });
    this.collectionSizeContacts = this.filteredContacts.length;
  }

  changeSelectionSupplyCenterCity() {
    this.selectedContact = null;
    
    this.filteredSCContacts = this.contacts.filter(c => {
      if (c.supplyCenterCity == this.selectedCity) {
          return c;
      }
    });
    this.filteredSCContacts.sort(function(a,b){
      var compareValue = a.lastname.localeCompare(b.lastname);
      return  compareValue === 0 ? a.firstname.localeCompare(b.firstname) : compareValue;
    });
    this.collectionSizeSCContacts = this.filteredSCContacts.length;
  }

  getContactsPage(): Contact[] {
    return this.filteredContacts
      .slice((this.pageContacts - 1) * this.pageSizeContacts, (this.pageContacts - 1) * this.pageSizeContacts + this.pageSizeContacts);
  }

  getSupplyCenterContactsPage(): Contact[] {
    return this.filteredSCContacts
      .slice((this.pageSCContacts - 1) * this.pageSizeSCContacts, (this.pageSCContacts - 1) * this.pageSizeSCContacts + this.pageSizeSCContacts);
  }

  public beforeTabChange($event: NgbTabChangeEvent) {
    this.selectedContact = null;
  }

  rowSelected(c: Contact){
    this.selectedContact = c;
  }

}
