import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Emergency } from './shared/emergency';
import { environment } from './../environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EmergencyService {
  
  // Define API
  apiURL: string;

  constructor(private http: HttpClient) {
    this.apiURL = environment.apiUrl + '/api/collections/get'
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  

  getEmergencys(): Observable<Emergency[]> {
    return this.http.get(this.apiURL + '/Notfall')
    .pipe(
      retry(1),
      map(response => {
        const entries = response['entries'];
        return entries.map((entry) => {
          let retE = new Emergency(entry);
          retE.images.forEach(i => this.http.get(environment.apiUrl + i.path, { responseType: 'blob' })
            .subscribe(r => {},err => console.log('error while loading image: '+ err)))
          return retE;
        });
      })
    )
  }
}