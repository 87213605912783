
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultSingletonsService } from './default.singletons.service';
import { CommunicationFlowContactList } from './shared/communication-flow-contact';

@Injectable({
  providedIn: 'root'
})

export class CommunicationFlowContactService extends DefaultSingletonsService {

  getContacts(s = 'KontakteInformationsfluss'): Observable<CommunicationFlowContactList> {
    return this.http.post(this.apiURL + '/' + s, { populate: 3 }, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => { return new CommunicationFlowContactList(response) })
      )
  }
}