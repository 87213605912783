import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AmpouleKitService } from 'src/app/ampoule-kit.service';
import { AmpouleKit } from 'src/app/shared/ampoule-kit';

@Component({
  selector: 'app-ampoule-kit',
  templateUrl: './ampoule-kit.component.html',
  styleUrls: ['./ampoule-kit.component.scss']
})
export class PrepairAmpouleKitComponent implements OnInit {

  readonly nullSelectionValue = "---";
  ampouleKit: AmpouleKit[] = [];
  filteredAmpouleKit: AmpouleKit[] = [];
  selectedAmpouleKit = null;

  pageAmpouleKit = 1;
  pageSizeAmpouleKit = 10;
  collectionSizeAmpouleKit = 0;

  constructor(public restApi: AmpouleKitService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadAmpouleKit(params.col);
    });
  }

  loadAmpouleKit(collection = undefined) {
    this.restApi.getAmpouleKit(collection).subscribe((pks) => {
      this.ampouleKit = pks;
      this.filteredAmpouleKit = pks;
      this.collectionSizeAmpouleKit = this.filteredAmpouleKit.length;
    });
  }

  rowSelected(pk: AmpouleKit){
    this.selectedAmpouleKit = pk;
  }

  getAmpouleKitPage(): AmpouleKit[] {
    return this.filteredAmpouleKit
      .slice((this.pageAmpouleKit - 1) * this.pageSizeAmpouleKit, (this.pageAmpouleKit - 1) * this.pageSizeAmpouleKit + this.pageSizeAmpouleKit);
  }

  print() {
    var pageSizeBefore = this.pageSizeAmpouleKit;
    this.pageSizeAmpouleKit = this.collectionSizeAmpouleKit;
    setTimeout(() => {
      window.print();
      this.pageSizeAmpouleKit = pageSizeBefore;
    });
  }

}
