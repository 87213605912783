import { Component, OnInit, Input } from '@angular/core';
import { graphviz }  from 'd3-graphviz';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-graphviz',
  template: '<div id="graphviz_output"></div>',
  host: {
    '(window:message)': 'onMessageEvent($event)'
  }
})
export class GraphvizComponent implements OnInit {
  private _graph: string = 'digraph {a -> b}';

  constructor() {
  }

  @Input()
  set graph(graph: string) {
    this._graph = (graph && graph.trim()) || 'digraph {no -> graph}';

  }

  ngOnInit(): void {
    this.updateGraph();
  }

  private updateGraph(){
    graphviz('#graphviz_output', {useWorker: false, zoom: false}).renderDot(this._graph);
  }

  public onMessageEvent(event: MessageEvent) {
    console.log('message received:  ', event);
    if (environment.apiUrl.localeCompare(event.origin) === 0) {
      console.log('entry: ',event.data.entry); 
      this._graph = event.data.entry['schema'];
      this.updateGraph();
    }
  }
}
