
export class MedicalCase {
    _id: string = null;
    indication: string = null;
    medicament: string = null;
    agent: string = null;
    issue: string = null;
    form: string[] = [];
    
    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
    }
}
