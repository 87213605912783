import { Component, OnInit } from '@angular/core';
import { SkiRegionService } from 'src/app/ski-region.service';
import { SkiRegion } from 'src/app/shared/ski-region';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-prepair-ski-region',
  templateUrl: './ski-region.component.html',
  styleUrls: ['./ski-region.component.scss']
})
export class PrepairSkiRegionComponent implements OnInit {
  
  readonly nullSelectionValue = "---";
  skiCountrys: string[] = [this.nullSelectionValue];
  selectedSkiCountry: string = this.nullSelectionValue;
  skiRegions: SkiRegion[] = [];
  skiRegionsCountry: SkiRegion[] = [];
  selectedSkiRegion: SkiRegion = null;

  page = 1;
  pageSize = 5;
  collectionSize = 0;

  constructor(public restApi: SkiRegionService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadSkiRegions(params.col);
    });
  }

  changeSkiCountry() {
    //console.log("selectedSkiCountry: " + this.selectedSkiCountry);
    this.skiRegionsCountry = this.skiRegions.filter(sr => {
      if (sr.country.length == 0 || sr.country == this.selectedSkiCountry) {
        return sr;
      }
    });
    this.selectedSkiRegion = null;
    this.skiRegionsCountry.sort((a, b) => {
      if (a.country.length == 0 && b.country.length == 0) {
        return 0;
      }
      else if (a.country.length == 0 && b.country.length > 0) {
        return -1
      }
      else if (a.country.length > 0 && b.country.length == 0) {
        return 1
      }
      return a.place.localeCompare(b.place)
    })
    this.page = 1;
    this.collectionSize = this.skiRegionsCountry.length;
  }

  loadSkiRegions(collection = undefined) {
    return this.restApi.getSkiRegions(collection).subscribe((srs) => {
      this.skiRegions = srs;
      const map = new Map();
      for (const sr of srs) {
        if (sr.country.length > 0 && !map.has(sr.country)) {
          map.set(sr.country, true);
          this.skiCountrys.push(sr.country);
        }
      }
      this.skiCountrys.sort();
    });
  }

  rowSelected(sr: SkiRegion) {
    this.selectedSkiRegion = sr;
    // console.log(this.selectedSkiRegion)
  }

  getSkiRegionsPage(): SkiRegion[] {
    return this.skiRegionsCountry
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

}
