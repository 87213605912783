import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { MedicalCase } from './shared/medical-case';

@Injectable({
  providedIn: 'root'
})

export class MedicalCaseService extends DefaultService {

  getMedicalCase(col = 'Arztkoffer'): Observable<MedicalCase[]> {
    return this.http.get(this.apiURL + '/' + col, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => {
          const entries = response['entries'];
          return entries.map((entry) => {
            return new MedicalCase(entry);
          });
        }),
        map(entries => entries.sort(function(a,b){
          var compareIndication = a.indication.localeCompare(b.indication);
          return  compareIndication === 0 ? a.medicament.localeCompare(b.medicament) : compareIndication;
        }))
      )
  }
}