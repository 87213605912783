import { Component, OnInit } from '@angular/core';
import { EmergencyCall } from '../shared/emergency-call';
import { EmergencyCallService } from '../emergency-call.service';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-emergency-call',
  templateUrl: './emergency-call.component.html',
  styleUrls: ['./emergency-call.component.scss']
})
export class EmergencyCallComponent implements OnInit {

  emergencyCall: EmergencyCall;
  emergencyAllowed: boolean;

  constructor(private config: ConfigService, public restApi: EmergencyCallService) { }

  ngOnInit() {
    console.log(this.config)
    this.config.isEmergencyAllowed().then((emergency: boolean) => {
      this.emergencyAllowed = emergency;
      if(this.emergencyAllowed){
        this.loadEmergencyCall();
      }
    });
  }

  loadEmergencyCall(){
    this.restApi.getEmergencyCall().subscribe(ec => {
      this.emergencyCall = ec
    });
  }

}
