import { environment } from '../../environments/environment';

export class Asset {
    path: string;
    title: string;

    constructor(values: Object = {}) {
        console.log(values)
        this.path = environment.apiUrl + '/storage/uploads' + values['path'];
        this.title = values['title'];
    }
}