
export class Disease {
    _id: string = null;
    title: string = null;
    disease: DiseaseSub[];
    remark: string = null;
    
    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
        this.disease = []
        if (values['disease']) {
            values['disease'].forEach(d => {
                this.disease.push(new DiseaseSub(d))
            });
        }
    }
}

export class DiseaseSub {
    _id: string = null;
    disease: string = null;
    definition: string = null;
    cave: string = null;
    remark: string = null;
    action: StringValue[];

    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
        this.action = []
        if (values['action']) {
            values['action'].forEach(s => {
                this.action.push(new StringValue(s));
            })
        }
    }
}

class StringValue {
    value: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}