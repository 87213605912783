export class Config {
    users: string[] = [];
    emergency: boolean = false;
    urls: ConfigURL[];

    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
        this.urls = []
        if (values['urls']) {
            values['urls'].forEach(u => {
                this.urls.push(new ConfigURL(u.value))
            });
        }
    }
}


export class ConfigURL {
    title: string = null;
    path: string = null;
    collection: string = null;
    singleton: string = null;

    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                if(typeof values[key] === "string"){
                    this[key] = values[key].length === 0 ? undefined : values[key];
                }
                else{
                    this[key] = values[key];
                }
            }
        }
    }
}