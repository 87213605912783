import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InternalGuideFilesService } from 'src/app/internalguidefiles.service';
import { InternalGuideFiles } from 'src/app/shared/internal-guide-files';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class InternalGuideFilesComponent implements OnInit {

  igFiles: InternalGuideFiles;
  
  constructor(public restApi: InternalGuideFilesService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadInternalGuideFiles(params.col);
    });
  }

  loadInternalGuideFiles(collection = undefined) {
    this.restApi.getInternalGuideFiles(collection).subscribe((ifg) => this.igFiles = ifg);
  }

  getInternalGuideFiles(){
    return this.igFiles;
  }
}
