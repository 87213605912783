
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultSingletonsService } from './default.singletons.service';
import { InternalGuideFiles} from './shared/internal-guide-files';

@Injectable({
  providedIn: 'root'
})

export class InternalGuideFilesService extends DefaultSingletonsService {

  getInternalGuideFiles(s = 'InternalGuideFiles'): Observable<InternalGuideFiles> {
    return this.http.get(this.apiURL + '/' + s, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => { return new InternalGuideFiles(response)})
      )
  }
}