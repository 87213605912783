import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Config, ConfigURL } from '../../shared/config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class PrepairHomeComponent implements OnInit {

  public urls: ConfigURL[];

  constructor(private config: ConfigService) { }

  ngOnInit() {
    this.config.getUrlElements('/prepair').then((urls: ConfigURL[]) => {
      this.urls = urls;
    });

  }

}
