import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PainkillerService } from 'src/app/painkiller.service';
import { Painkiller } from 'src/app/shared/painkiller';

@Component({
  selector: 'app-painkiller',
  templateUrl: './painkiller.component.html',
  styleUrls: ['./painkiller.component.scss']
})
export class PrepairPainkillerComponent implements OnInit {

  readonly nullSelectionValue = "---";
  painkiller: Painkiller[] = [];
  filteredPainkiller: Painkiller[] = [];
  selectedPainkiller = null;

  pagePainkiller = 1;
  pageSizePainkiller = 10;
  collectionSizePainkiller = 0;

  constructor(public restApi: PainkillerService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadPainkiller(params.col);
    });
  }

  loadPainkiller(collection = undefined) {
    this.restApi.getPainkiller(collection).subscribe((pks) => {
      this.painkiller = pks;
      this.filteredPainkiller = pks;
      this.collectionSizePainkiller = this.filteredPainkiller.length;
    });
  }

  rowSelected(pk: Painkiller){
    this.selectedPainkiller = pk;
  }

  getPainkillerPage(): Painkiller[] {
    return this.filteredPainkiller
      .slice((this.pagePainkiller - 1) * this.pageSizePainkiller, (this.pagePainkiller - 1) * this.pageSizePainkiller + this.pageSizePainkiller);
  }

  print() {
    var pageSizeBefore = this.pageSizePainkiller;
    this.pageSizePainkiller = this.collectionSizePainkiller;
    setTimeout(() => {
      window.print();
      this.pageSizePainkiller = pageSizeBefore;
    });
  }

}
