
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class DefaultSingletonsService {
  // Define API
  apiURL: string;

  constructor(protected http: HttpClient, private authService: AuthService) {
    this.apiURL = environment.apiUrl +  '/api/singletons/get'
  }

  getHttpHeaders(): HttpHeaders {
    // Http Options
    let api_key: string
    this.authService.currentUser.subscribe(u => api_key = (u === undefined  || u === null ? "" : u.api_key));
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Cockpit-Token': api_key
    })
  }
}