import { Component, OnInit, NgModule } from '@angular/core';

@Component({
  selector: 'app-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.scss']
})
export class EmergencyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
