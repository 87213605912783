export class EmergencyCall {
    _id: string = null;
    headline: string = null;
    providerName: string = null;
    providerNumber: string = null;
    decisionInfo: string = null;
    informationFlow: string[] = [];
    process_1: ProcessSteps = null;
    process_2: ProcessSteps = null;
    process_3: ProcessSteps = null;
    process_4: ProcessSteps = null;
    
    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
        this.informationFlow = []
        if (values['informationFlow']) {
            values['informationFlow'].forEach(i => {
                this.informationFlow.push(i.value)
            });
        }
        this.process_1 = new ProcessSteps(values['process_1'])
        this.process_2 = new ProcessSteps(values['process_2'])
        this.process_3 = new ProcessSteps(values['process_3'])
        this.process_4 = new ProcessSteps(values['process_4'])
    }
}

export class ProcessSteps {
    _id: string = null;
    headline: string = null;
    steps: string[] = [];
    constructor(values: Object = {}) {
        const keys = Object.keys(this);
    
        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
        this.steps = []
        if (values['steps']) {
            values['steps'].forEach(i => {
                this.steps.push(i.value)
            });
        }
    }
}