import { Component, OnInit } from '@angular/core';
import { MedicalCase } from 'src/app/shared/medical-case';
import { MedicalCaseService } from 'src/app/medicalcase.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-medical-case',
  templateUrl: './medical-case.component.html',
  styleUrls: ['./medical-case.component.scss']
})
export class PrepairMedicalCaseComponent implements OnInit {

  readonly nullSelectionValue = "---";
  medicalCase: MedicalCase[] = [];
  mcIndication: string[] = [];
  selectedIndication: string = this.nullSelectionValue;
  filteredMedicalCase: MedicalCase[] = [];

  pageMedicalCase = 1;
  pageSizeMedicalCase = 10;
  collectionSizeMedicalCase = 0;

  constructor(public restApi: MedicalCaseService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadMedicalCase(params.col);
    });
  }

  loadMedicalCase(collection = undefined) {
    this.restApi.getMedicalCase(collection).subscribe(mcs => {
        this.medicalCase = mcs;
        this.filteredMedicalCase = mcs;
        this.collectionSizeMedicalCase = this.filteredMedicalCase.length;
        const mapI = new Map();
        this.mcIndication = [this.nullSelectionValue];
        for (const mc of mcs) {
          if (!mapI.has(mc.indication)) {
            mapI.set(mc.indication, true);
            this.mcIndication.push(mc.indication);
          }
        }
      });
  }

  getMedicalCasePage(): MedicalCase[] {
    return this.filteredMedicalCase
      .slice((this.pageMedicalCase - 1) * this.pageSizeMedicalCase, (this.pageMedicalCase - 1) * this.pageSizeMedicalCase + this.pageSizeMedicalCase);
  }

  changeSelection() {
    if (this.selectedIndication == this.nullSelectionValue) {
      this.filteredMedicalCase = this.medicalCase;
    }
    else {
      this.filteredMedicalCase = this.medicalCase.filter(mc => {
        if (mc.indication == this.selectedIndication) {
          return mc;
        }
      });
    }
    this.pageMedicalCase = 1;
    this.pageSizeMedicalCase = this.collectionSizeMedicalCase;
  }

  print(){
    var pageSizeBefore = this.pageSizeMedicalCase;
    this.pageSizeMedicalCase = this.collectionSizeMedicalCase;
    setTimeout(() => {
      window.print();
      this.pageSizeMedicalCase = pageSizeBefore;
    });
  }
}
