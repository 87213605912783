import {Asset} from './asset'

export class InternalGuideFiles {
    files: Asset[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
        this.files = []
        console.log(values['files'])
        if (values['files']) {
            values['files'].forEach(a => {
                    this.files.push(new Asset(a['value']))
            });
        }
    }
}