import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/config.service';
import { ConfigURL } from 'src/app/shared/config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class InternalHomeComponent implements OnInit {

  public urls: ConfigURL[];

  constructor(private config: ConfigService) { }

  ngOnInit() {
    this.config.getUrlElements('/internalguide').then((urls: ConfigURL[]) => {
      this.urls = urls;
    });

  }

}
