import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from './shared/user';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  loggedIn = false;
  // Define API
  apiURL: string;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.apiURL = environment.apiUrl + '/api/cockpit/authUser?token=' + environment.apiToken;
    let userJson = sessionStorage.getItem('currentUser');
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(userJson));
    this.currentUser = this.currentUserSubject.asObservable();  
    if(userJson != null){ 
      this.loggedIn = true;
    }
  }

  isAuthenticated() {
    const promise = new Promise(
      (resolve, reject) => {
        resolve(this.loggedIn);
      }
    );
    return promise;
  }

  login(user: string, password: string) {
    return this.http.post<any>(this.apiURL, { user, password })
      .pipe(map(resp_user => {
        // store user details and session storage to keep user logged in between page refreshes
        let retU = new User(resp_user);
        sessionStorage.setItem('currentUser', JSON.stringify(retU));
        this.currentUserSubject.next(retU);
        this.loggedIn = true;
        return retU;
      }));

  }

  logout() {
    this.loggedIn = false;
    // remove user from session storage to log user out
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
