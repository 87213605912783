import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, retry } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { Config } from './shared/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends DefaultService {

  private config: Config;

  loadConfig(): Observable<Config[]> {
    return this.http.get(this.apiURL + '/Config', { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => {
          const entries = response['entries'];
          let userid;
          this.authService.currentUser.subscribe(x => userid = x._id);
          let e = entries.map((entry) => {
            let c = new Config(entry);
            if (c.users.indexOf(userid) >= 0) {
              return c;
            }
          }).filter(e => {
            return e !== undefined;
          });
          e.push(new Config())
          return e;
        })
      )
  }

  isEmergencyAllowed() {
    if (this.config == undefined) {
      console.log('config needs to be loaded')
      return new Promise((resolve, reject) => {
        this.loadConfig().pipe(first()).subscribe(success => {
          this.config = success[0];
          resolve(success[0].emergency);
        });
      });
    }
    else {
      const promise = new Promise(
        (resolve, reject) => {
          resolve(this.config.emergency);
        }
      );
      return promise;
    }
  }

  getUrlElements(path: string) {
    if (this.config == undefined) {
      return new Promise((resolve, reject) => {
        this.loadConfig().pipe(first()).subscribe(success => {
          this.config = success[0];
          resolve(this.filterUrlElements(path));
        });
      });
    }
    else {
      const promise = new Promise(
        (resolve, reject) => {
          resolve(this.filterUrlElements(path));
        }
      );
      return promise;
    }
  }

  filterUrlElements(path: string) {
    return this.config.urls.map(u => {
      if (u.path.startsWith(path)) {
        return u;
      }
    }).filter(e => {
      return e !== undefined;
    });
  }

}
