import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitLineBreaks'
})
export class SplitLineBreaksPipe implements PipeTransform {

  transform(value: string): string[] {
    if (value != null) {
      return value.split('\n');
    }
  }

}
