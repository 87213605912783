import { Contact } from './contact';

export class CommunicationFlowContactList {
    _id: string = null;
    contacts: CommunicationFlowContact[] = [];
    
    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
        this.contacts = []
        if (values['contact']) {
            values['contact'].forEach(c => {
                this.contacts.push(new CommunicationFlowContact(c['value']))
            });
        }
    }
}

class CommunicationFlowContact {
    descricption: string = null;
    contact: Contact = null;
    
    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
        if (values['contact']) {
            this.contact = new Contact(values['contact']);
        }
    }
}