import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NavbarComponent } from './navbar/navbar.component';
import { GraphvizComponent } from './graphviz/graphviz.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { EmergencyComponent } from './emergency/emergency.component';
import { EmergencyHomeComponent } from './emergency/home/home.component';
import { EmergencyDetailComponent } from './emergency/detail/detail.component';
import { PrepairComponent } from './prepair/prepair.component';
import { PrepairHomeComponent } from './prepair/home/home.component';
import { PrepairSkiRegionComponent } from './prepair/ski-region/ski-region.component';
import { PrepairContactComponent } from './prepair/contact/contact.component';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { HttpErrorIntercepterService } from './http-error-intercepter.service';
import { PrepairShareComponent } from './prepair/share/share.component';
import { PrepairMedicalCaseComponent } from './prepair/medical-case/medical-case.component';
import { PrepairMedicalBackpackComponent } from './prepair/medical-backpack/medical-backpack.component';
import { PrepairPhysioBackpackComponent } from './prepair/physio-backpack/physio-backpack.component';
import { PrepairPainkillerComponent } from './prepair/painkiller/painkiller.component';
import { PrepairAmpouleKitComponent } from './prepair/ampoule-kit/ampoule-kit.component';
import { InternalComponent } from './internal/internal.component';
import { InternalHomeComponent } from './internal/home/home.component';
import { InternalDiseaseComponent } from './internal/disease/disease.component';
import { InternalMedicineComponent } from './internal/medicine/medicine.component';
import { InternalGuideFilesComponent } from './internal/files/files.component';
import { SplitLineBreaksPipe } from './split-line-breaks.pipe';
import { EmergencyCallComponent } from './emergency-call/emergency-call.component';
import { CommunicationFlowComponent } from './communication-flow/communication-flow.component';
import { ConfigService } from './config.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    NavbarComponent,
    GraphvizComponent,
    EmergencyComponent,
    EmergencyHomeComponent,
    EmergencyDetailComponent,
    PrepairComponent,
    PrepairHomeComponent,
    PrepairSkiRegionComponent,
    PrepairContactComponent,
    LoginComponent,
    PrepairShareComponent,
    PrepairMedicalCaseComponent,
    PrepairMedicalBackpackComponent,
    PrepairPhysioBackpackComponent,
    PrepairPainkillerComponent,
    PrepairAmpouleKitComponent,
    InternalComponent,
    InternalHomeComponent,
    InternalDiseaseComponent,
    InternalMedicineComponent,
    InternalGuideFilesComponent,
    SplitLineBreaksPipe,
    EmergencyCallComponent,
    CommunicationFlowComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    Title,
    AuthService,{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorIntercepterService, multi: true },
    ConfigService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
