import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Emergency } from './shared/emergency';
import { environment } from './../environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { Contact } from './shared/contact';

@Injectable({
  providedIn: 'root'
})

export class ContactService extends DefaultService {

  getContacts(col = 'Kontakt'): Observable<Contact[]> {
    return this.http.get(this.apiURL + '/' + col, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => {
          const entries = response['entries'];
          return entries.map((entry) => {
            return new Contact(entry);
          });
        })
      )
  }
}