export class User {
    _id: string = null;
    user: string = null;
    email: string = null;
    api_key: string = null;

    constructor(values: Object = {}) {
        const keys = Object.keys(this);

        for (const key of keys) {
            if (values.hasOwnProperty(key)) {
                this[key] = values[key];
            }
        }
    }
}
