
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { Disease } from './shared/disease';

@Injectable({
  providedIn: 'root'
})

export class DiseaseService extends DefaultService {

  getDisease(col = 'Erkrankungen'): Observable<Disease[]> {
    return this.http.post(this.apiURL + '/' + col, { populate: 1 }, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => {
          const entries = response['entries'];
          return entries.map((entry) => {
            return new Disease(entry);
          });
        })
      )
  }
}