import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiseaseService } from 'src/app/disease.service';
import { Disease } from 'src/app/shared/disease';

@Component({
  selector: 'app-disease',
  templateUrl: './disease.component.html',
  styleUrls: ['./disease.component.scss']
})
export class InternalDiseaseComponent implements OnInit {

  readonly nullSelectionValue = "---";

  disease: Disease[] = [];
  selectedDisease: Disease = null;
  selectedDiseaseTitle = null;

  constructor(public restApi: DiseaseService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadDisease(params.col);
    });
  }

  loadDisease(collection = undefined) {
    this.restApi.getDisease(collection).subscribe((ml) => {
      this.disease = ml;
    });
  }

  changeSelectionDisease() {
    if (this.selectedDiseaseTitle == this.nullSelectionValue) {
      this.selectedDisease = null;
    }
    else {
      this.disease.filter(d => {
        if (d.title == this.selectedDiseaseTitle) {
          this.selectedDisease = d;
        }
      });
    }
  }
}
