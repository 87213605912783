import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { Share } from './shared/share';

@Injectable({
  providedIn: 'root'
})

export class ShareService extends DefaultService {

  getShares(col = 'Share'): Observable<Share[]> {
    return this.http.get(this.apiURL + '/' + col, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => {
          const entries = response['entries'];
          return entries.map((entry) => {
            return new Share(entry);
          });
        }),
        map(entries => entries.sort(function (a, b) {
          if (a.highlight === b.highlight) {
            return a.name.localeCompare(b.name);
          } else if (a.highlight && !b.highlight) {
            return -1;
          }
          else {
            return 1;
          }
        }))
      )
  }
}