import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PrepairComponent } from './prepair/prepair.component';
import { PrepairHomeComponent } from './prepair/home/home.component';
import { PrepairSkiRegionComponent } from './prepair/ski-region/ski-region.component';
import { EmergencyComponent } from './emergency/emergency.component';
import { EmergencyHomeComponent } from './emergency/home/home.component';
import { EmergencyDetailComponent } from './emergency/detail/detail.component';
import { GraphvizComponent } from './graphviz/graphviz.component';
import { AboutComponent } from './about/about.component';
import { AuthGuard } from './auth-guard.service';
import { LoginComponent } from './login/login.component';
import { PrepairContactComponent } from './prepair/contact/contact.component';
import { PrepairShareComponent } from './prepair/share/share.component';
import { PrepairMedicalCaseComponent } from './prepair/medical-case/medical-case.component';
import { PrepairMedicalBackpackComponent} from './prepair/medical-backpack/medical-backpack.component';
import { PrepairPhysioBackpackComponent } from './prepair/physio-backpack/physio-backpack.component';
import { PrepairPainkillerComponent } from './prepair/painkiller/painkiller.component';
import { PrepairAmpouleKitComponent } from './prepair/ampoule-kit/ampoule-kit.component';
import { EmergencyCallComponent } from './emergency-call/emergency-call.component';
import { InternalComponent } from './internal/internal.component';
import { InternalHomeComponent } from './internal/home/home.component';
import { InternalMedicineComponent } from './internal/medicine/medicine.component';
import { InternalGuideFilesComponent } from './internal/files/files.component';
import { InternalDiseaseComponent } from './internal/disease/disease.component';
import { CommunicationFlowComponent } from './communication-flow/communication-flow.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'prepair',
    component: PrepairComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [{
      path: '',
      component: PrepairHomeComponent
    },
    {
      path: 'skiregion',
      component: PrepairSkiRegionComponent
    },
    {
      path: 'contact',
      component: PrepairContactComponent
    },
    {
      path: 'share',
      component: PrepairShareComponent
    },
    {
      path: 'medicalcase',
      component: PrepairMedicalCaseComponent
    },
    {
      path: 'medicalbackpack',
      component: PrepairMedicalBackpackComponent
    },
    {
      path: 'physiobackpack',
      component: PrepairPhysioBackpackComponent
    },
    {
      path: 'painkiller',
      component: PrepairPainkillerComponent
    },
    {
      path: 'ampoulekit',
      component: PrepairAmpouleKitComponent
    }]
  },
  {
    path: 'emergency',
    component: EmergencyComponent,
    children: [{
      path: '',
      component: EmergencyHomeComponent
    },
    {
      path: 'with',
      component: EmergencyDetailComponent
    },
    {
      path: 'without',
      component: EmergencyDetailComponent
    }]
  },
  {
    path: 'emergencycall',
    canActivate: [AuthGuard],
    component: EmergencyCallComponent,
  },
  {
    path: 'internalguide',
    component: InternalComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [{
      path: '',
      component: InternalHomeComponent
    },
    {
      path: 'disease',
      component: InternalDiseaseComponent
    },
    {
      path: 'medicine',
      component: InternalMedicineComponent
    },
    {
      path: 'files',
      component: InternalGuideFilesComponent
    }]
  },
  {
    path: 'communicationflow',
    canActivate: [AuthGuard],
    component: CommunicationFlowComponent,
  },
  {
    path: 'graph',
    component: GraphvizComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
