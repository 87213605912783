

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { CommunicationFlow } from './shared/communication-flow';

@Injectable({
  providedIn: 'root'
})

export class CommunicationFlowService extends DefaultService {

  getCommunicationFlow(col = 'Informationsfluss'): Observable<CommunicationFlow[]> {
    return this.http.get(this.apiURL + '/' + col, { headers: this.getHttpHeaders() })
      .pipe(
        retry(1),
        map(response => {
          const entries = response['entries'];
          return entries.map((entry) => {
            return new CommunicationFlow(entry);
          });
        })
      )
  }
}