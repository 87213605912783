import { Component, OnInit } from '@angular/core';
import { EmergencyService } from "../../emergency.service";
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-emergency-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class EmergencyDetailComponent implements OnInit {
  emergencysWith: any = [];
  selectedIndex = -1;
  imageUrl: string;
  typeParam: string;
  typePath: string;

  constructor(
    public restApi: EmergencyService,
    private route: ActivatedRoute
  ) {
    this.imageUrl = environment.apiUrl
    console.log(this.route.url['value'])
    if (this.route.url['value'].length == 1) {
      this.typePath = this.route.url['value'][0].path
      switch(this.typePath){
        case 'with':
          this.typeParam = "mit Trauma";
          break;
        case 'without':
          this.typeParam = "ohne Trauma";
          break;
        default:
            console.log('route path is not handled: '+this.route.url['value'][1])
        }
    }
  }

  ngOnInit() {
    this.loadEmergencys()
  }

  loadEmergencys() {
    return this.restApi.getEmergencys().subscribe((emergencys) => {
      this.emergencysWith = emergencys.filter(e => e.type === this.typeParam);
    });
  }

  getSelectedEmergency() {
    if (this.selectedIndex == -1)
      return null;
    else
      return this.emergencysWith[this.selectedIndex];
  }

  setSelectedEmergency(index) {
    this.selectedIndex = index;
  }

}
