import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorIntercepterService implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log("HttpErrorIntercepterService ", err)
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authService.logout();
                this.router.navigate(['login']);
            }
            else if(err.status > 500 && err.status < 600){
                // Get server-side error
                window.alert(`Fehler beim Laden der Daten:\n\nError Code: ${err.status}\nMessage: ${err.message}`);
            }
            else{
                //const error = err.error.message || err.statusText;
                return throwError(err);
            }
        }))
    }
}